<template>
<b-container fluid>
  <div class="child-container">
    <h1>Overview</h1>
    <b-dropdown text="Filter By" class="mt-3 mb-3 dropdown" variant="light">
      <b-dropdown-item>Needs Reviewed</b-dropdown-item>
      <b-dropdown-item>Needs Approved</b-dropdown-item>
      <b-dropdown-item>Flagged</b-dropdown-item>
      <b-dropdown-item>On Hold</b-dropdown-item>
      <b-dropdown-item>My Requests</b-dropdown-item>
    </b-dropdown>
    <hr class="w-100">
      <b-table
      head-variant="dark"
      small
      bordered
      :items="items"
      :fields="fields"
      class="mt-4"
      >
      <template #cell(actions)="row">
        <b-button size="sm" class="mr-1">
          Info modal
        </b-button>
        <b-button size="sm" @click="row.toggleDetails">
          Details
        </b-button>
      </template>
      </b-table>
    <b-pagination
      align="left"
      size="sm"
      class="my-1"
    ></b-pagination>
  </div>
</b-container>
</template>
<script>
export default {
  data () {
    return {
      fields: [
        { key: 'first_name', sortable: true },
        { key: 'last_name', sortable: true },
        { key: 'age', sortable: true },
        { key: 'actions', sortable: false }
      ],
      items: [
        { age: 40, first_name: 'Dickerson', last_name: 'Macdonald' },
        { age: 21, first_name: 'Larsen', last_name: 'Shaw' },
        { age: 89, first_name: 'Geneva', last_name: 'Wilson' },
        { age: 38, first_name: 'Jami', last_name: 'Carney' }
      ]
    }
  }
}
</script>

<style lang="scss" scoped>
  *{
    text-align: left;
  }
  .child-container{
    height: 100%;
    max-width: 85%;
    margin: 0px 50px 0px 250px;
    padding: 35px;

    .dropdown{
      border: 1px solid #ccc;
    }

    .table {
      box-shadow: 0px 1px 3px .5px #999 !important;
    }
  }
  hr{
    border-color: #ccc;
  }
</style>

<template>
  <div id="app">
    <div class="parent-container">
      <Nav />
      <FloatButton />
      <NewRequest />
      <router-view />
    </div>
  </div>
</template>

<script>
import Nav from './components/Nav.vue'
import FloatButton from './components/FloatButton.vue'
import NewRequest from './components/NewRequest.vue'

export default {
  name: 'app',
  components: {
    Nav,
    FloatButton,
    NewRequest
  }
}
</script>
<style lang="scss">
html, body{
  height: 100%;
  margin: 0px;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000;
  height: 100%;
  overflow: hidden;

  .parent-container{
    height: 100%;
    width: 100%;
    background: #f3f3f3;
  }
}
</style>

<template>
  <div id='nav'>
    <div>
    <b-navbar variant="dark" class="navbar-custom" fixed>
         <b-navbar-brand href="#">
          <div class="img-div">
            <img src="@/assets/logo-fnd.png">
          </div>
        </b-navbar-brand>
        <b-button variant="outline-secondary" class="mr-4">
          <b-icon icon="list"></b-icon>
        </b-button>
        <b-navbar-nav class="px-3">
          <b-nav-form>
            <b-form-input type="search" size="sm" class="search" placeholder="Search"></b-form-input>
          </b-nav-form>
        </b-navbar-nav>
        <b-navbar-nav class="ml-auto">
          <b-nav-item right>
          <b-button variant="secondary" class="mr-4 button">
            <b-icon icon="power" aria-hidden="true"></b-icon> Logout
            </b-button>
        </b-nav-item>
        </b-navbar-nav>
    </b-navbar>
    <b-nav vertical class="nav">
      <b-nav-item>
        <router-link to="/overview">
        <b-icon icon="house-fill"></b-icon>
        Overview
        </router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link to="/export">
        <b-icon icon="cloud-download-fill"></b-icon>
        Export
        </router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link to='/accounts'>
        <b-icon icon="wallet-fill"></b-icon>
        Accounts
        </router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link to='/expense'>
        <b-icon icon="bag-fill"></b-icon>
        Expense
        </router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link to='/vendors'>
        <b-icon icon="cart-fill"></b-icon>
        Vendors
        </router-link>
      </b-nav-item>
      <b-nav-item>
        <router-link to='/users'>
        <b-icon icon="people-fill"></b-icon>
        Users
        </router-link>
      </b-nav-item>
    </b-nav>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Nav'
}
</script>

<style scoped lang="scss">
$sruGreen: #007055;

#nav {
  .nav{
  height: stretch;
  width: 215px;
  position: fixed;
  z-index: 1;
  left: 0;
  background-color: #fff;
  overflow-x: hidden;
  border-right: 1px solid #ccc;

    li {
      border-bottom: 1px solid #ccc;
    }

    a {
    &:hover{
      color: #42b983;
    }
    padding: 10px 12px 6px 16px;
    display: flex;
    flex-flow: column;
    align-content: unset;
    align-items: center;
    text-decoration: none;
    font-size: 1em;
    color: $sruGreen;
    text-align: center;

    &.router-link-exact-active {
      color: #42b983;
    }
  }
  }

  .navbar{
     background: #fff !important;
    border-bottom: 2px solid #ccc;
    padding: 0;

    img{
      left: 0;
    }

    .navbar-brand{
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
    .img-div{
      background: $sruGreen;
      border-right: 1px solid #ccc;
      padding: 20px;
    }

    .search{
      border-radius: 50px;
      border: 1px solid #000;
      width: 900px !important;
    }

    .button{
      background: $sruGreen;
      color: white;

      &:hover{
        opacity: .75;
      }
    }
  }

}
</style>

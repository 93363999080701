<template>
  <div>
    <b-button id="popover-new-request" v-b-modal.modal-1 class="float">
      <b-icon icon="plus"></b-icon>
    </b-button>
    <b-popover target="popover-new-request" triggers="hover" placement="left">
      New Request
    </b-popover>
  </div>
</template>

<script>
export default {

}
</script>

<style lang="scss" scoped>
.float{
  position: fixed;
  width: 70px;
  height: 70px;
  bottom: 40px;
  right: 40px;
  background-color: #007055;
  color: #fff;
  border-radius: 50px;
  text-align: center;
  font-size: 1.75em;

  &:hover{
    box-shadow: 2px 2px 3px #999;
  }
}
</style>

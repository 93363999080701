import Vue from 'vue'
import VueRouter from 'vue-router'

import Home from '@/views/Home.vue'
import Overview from '@/views/Overview.vue'
import Export from '@/views/Export.vue'
import Accounts from '@/views/Accounts.vue'
import Expense from '@/views/Expense.vue'
import Vendors from '@/views/Vendors.vue'
import Users from '@/views/Users.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Home
  },
  {
    path: '/Overview',
    name: 'overview',
    component: Overview,
    meta: {
      title: 'Overview'
    }
  },
  {
    path: '/Export',
    name: 'export',
    component: Export
  },
  {
    path: '/Accounts',
    name: 'accounts',
    component: Accounts
  },
  {
    path: '/Expense',
    name: 'expense',
    component: Expense
  },
  {
    path: '/Vendors',
    name: 'vendors',
    component: Vendors
  },
  {
    path: '/Users',
    name: 'users',
    component: Users
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
